export default function ContactUs() {

  return (
    <div className="contactUs text-center">
      <div>
        <div>SKONTAKTUJ SIĘ Z NAMI</div> 
        <div>+48 539 700 112</div>
        <div>medyczne.centrumtacmed@gmail.com</div>
      </div>
    </div>
  );
}